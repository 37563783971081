import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import { H3Title, SectionTitle, SimpleLink } from 'src/uikit'
import { Container, Box, Stack, SimpleGrid, useBreakpointValue } from '@chakra-ui/react'
import Carousel from '../common/Carousel'

const Post = dynamic(() => import('./Post'))

interface Props {
  title: string
  action: string
  alt: string
  posts: Array<PressGlobal.BlogPostItem>
}

const BlogPostList: FC<Props> = ({ title, action, posts, alt }) => {
  const href = 'https://blog.carplanner.com'
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box bg="baseWhite"
      paddingBottom="xxl6" pt="xxl6">
      <Container as={Stack} maxW="6xl">
        {isMobile ?
          <>
            <H3Title>{title}</H3Title>
            <Carousel
              slidesPerView={1}
              spaceBetween={30}
              withPagination
              className="home-why-carousel"
            >
              {posts.map(post => {
                return (
                  <Carousel.Item key={post.title}>
                    <Post
                      key={post.title}
                      categories={post.categories}
                      title={post.title}
                      excerpt={post.excerpt}
                      image={post.image}
                      day={post.day}
                      month={post.month}
                      href={post.href}
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
            <Box pt="s">
              <SimpleLink action={action} href={href} title={alt} target="_blank" />
            </Box>
          </>
          :
          <>

            <SectionTitle title={title} action={action} alt={alt} href={href} />
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 3 }}
              spacing="l"
              textAlign="center"
            >
              {posts &&
                posts.map(post => (
                  <Post
                    key={post.title}
                    categories={post.categories}
                    title={post.title}
                    excerpt={post.excerpt}
                    image={post.image}
                    day={post.day}
                    month={post.month}
                    href={post.href}
                  />
                ))}
            </SimpleGrid>
          </>
        }
      </Container>
    </Box >
  )
}

export default BlogPostList
